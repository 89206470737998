<template>
  <v-container fill-height fluid class="register-pages">
    <router-view />
  </v-container>
</template>

<script>
import AxiosInstance from "@/core/services/axios.service";
import { isEmptyObject } from "jquery";

export default {
  data() {
    return {
      user: JSON.parse(window.localStorage.getItem("user")) || {},
    };
  },
  beforeCreate() {
    if (isEmptyObject(this.user)) {
      AxiosInstance.get("/users/register/" + this.$route.params.registerToken)
        .then((response) => {
          this.user = response.data;
          this.user = {
            ...this.user,
            register_token: this.$route.params.registerToken,
            address: {},
            parent_information: {
              address: {},
            },
            work_information: {},
          };
          window.localStorage.setItem("user", JSON.stringify(this.user));
        })
        .catch(() => {
          this.$router.push({ name: "404" });
        });
    }
    if (localStorage.getItem("activeForm")) {
      if (this.$route.name !== "register-form") {
        this.$router.push({ name: "register-form" });
      }
    }
  },
};
</script>
